import { logger } from '@sentry/utils';
import { notification } from 'antd';
import { getTranslations } from '../microservices/l10n';
import { getStoreValue, store } from '../stores/store';
import keyBy from 'lodash/keyBy';
import { loadProviders, loadStudios, loadSubproviders } from 'microservices/casino/providers';
import { getServiceUrl } from './api';
import { loadCountries, loadLicences, loadProvinces } from 'microservices/casino/licences';
import { loadFeatures, loadLabels, loadOperatingLanguages, loadRtpVersions, loadThemes } from 'microservices/casino';
import { loadTypes } from 'microservices/casino/types';
import { loadCurrencies } from 'microservices/casino/currencies';
import { isProd } from './util';
import { Tab } from 'rc-tabs/lib/interface';
import { CmsTranslation } from 'types/cms';
import { Country, Licence } from '@staycool/location';
import { getFallbackLanguage, getLanguages } from './language';
import { Currency, LanguageLobbyTabular } from 'types/casino';
import { getTranslationsByKeys } from '../microservices/cms/translations';
import { ClientName } from 'types/client';
const isProduction = isProd();

export const PROVIDER_NAMES_BY_SPLIT = {
    PLAYGO: [1],
    YGGDRASIL: [2],
    MICROGAMING: [3],
    PRAGMATIC: [9],
    NETENT: [6],
    ELK: [8],
    EVOLUTION: [4],
    HUB88: [7],
    ISOFTBET: [10],
    RELAX: [11],
    PUSH: [12, 7],
    NOLIMIT: [13, 7],
    QUICKSPIN: [14, 7],
    SPINOMENAL: [15],
    HACKSAW: [16],
    SUPERRGS: [17],
    STAKELOGIC: [18],
    LIGHTNWONDER: [19],
    PLAYTECH: [20],
};

export const RACKSPACE_BASE_URL = 'https://c73cea5fcd0f0f5611a3-df529c4a5c1bffc596b8b46601de1634.ssl.cf5.rackcdn.com';

export const SUPPORTED_LOBBY_COUNTRIES = [
    'Canada',
    'Iceland',
    'Norway',
    'Sweden',
    'Finland',
    'Estonia',
    'Chile',
    'Peru',
    'Ecuador',
    'Mexico',
];

export enum TYPE_NAME_STATUS {
    ERROR = 'error',
    WARNING = 'warning',
    NEUTRAL = '',
}

export interface CasinoTranslatableEntity {
    id?: number;
    name: string;
    translations?: Record<string, string>;
}

export interface CasinoGameFeatureEntity extends CasinoTranslatableEntity {
    showInCasino: boolean;
    showInLiveCasino: boolean;
}

export enum CasinoTranslationGroupKeys {
    Labels = 'ui.casino.labels',
    Themes = 'ui.casino.themes',
    Features = 'ui.casino.features',
    OperatingLanguages = 'ui.casino.operatingLanguages',
}

export const getUrl = (url: string) => getServiceUrl('casino', url);

export enum CasinoAvailableGameStatus {
    BOTH_DISABLED = 'BOTH_DISABLED',
    OPERATOR_DISABLED = 'OPERATOR_DISABLED',
    OPERATOR_ENABLED = 'OPERATOR_ENABLED',
    PROVIDER_DISABLED = 'PROVIDER_DISABLED',
    PROVIDER_ENABLED = 'PROVIDER_ENABLED',
    BOTH_ENABLED = 'BOTH_ENABLED',
}

const licences = {
    MT: 3,
    SE: 2,
    CAON: 34,
    EE: 1,
    MX: 35,
};

const gameTypes = {
    Slots: 1,
    Blackjack: 2,
    Roulette: 3,
    Baccarat: 4,
    Puntobanco: 5,
    Poker: 6,
    Bingo: 7,
    VideoPoker: 8,
    Dice: 9,
    Gameshow: 10,
    ScratchCard: 35,
    Other: 36,
    Crash: 37,
    Mines: 38,
    TablesGames: isProduction ? 44 : 39,
    LiveCasino: isProduction ? 45 : 40,
    ProgressiveJackpot: isProduction ? 42 : 41,
    ScratchCardStyleGames: isProduction ? 43 : 42,
    LiveDealerTables: isProduction ? 41 : 43,
};

export const PREFILLED_GAMETYPE_LICENCE = (isJackPotEnabled: boolean) => ({
    casino: {
        [gameTypes.Slots]: {
            [licences.MT]: gameTypes.Slots,
            [licences.SE]: gameTypes.Slots,
            [licences.CAON]: (() => (isJackPotEnabled ? gameTypes.ProgressiveJackpot : gameTypes.Slots))(),
            [licences.EE]: gameTypes.Slots,
            [licences.MX]: gameTypes.Slots,
        },
        [gameTypes.Blackjack]: {
            [licences.MT]: gameTypes.TablesGames,
            [licences.SE]: gameTypes.Blackjack,
            [licences.CAON]: gameTypes.TablesGames,
            [licences.EE]: gameTypes.Blackjack,
            [licences.MX]: gameTypes.Blackjack,
        },
        [gameTypes.Roulette]: {
            [licences.MT]: gameTypes.TablesGames,
            [licences.SE]: gameTypes.Roulette,
            [licences.CAON]: gameTypes.TablesGames,
            [licences.EE]: gameTypes.Roulette,
            [licences.MX]: gameTypes.Roulette,
        },
        [gameTypes.Baccarat]: {
            [licences.MT]: gameTypes.TablesGames,
            [licences.SE]: gameTypes.Baccarat,
            [licences.CAON]: gameTypes.TablesGames,
            [licences.EE]: gameTypes.Baccarat,
            [licences.MX]: gameTypes.Baccarat,
        },
        [gameTypes.Puntobanco]: {
            [licences.MT]: gameTypes.TablesGames,
            [licences.SE]: gameTypes.Puntobanco,
            [licences.CAON]: gameTypes.TablesGames,
            [licences.EE]: gameTypes.Puntobanco,
            [licences.MX]: gameTypes.Puntobanco,
        },
        [gameTypes.Poker]: {
            [licences.MT]: gameTypes.TablesGames,
            [licences.SE]: gameTypes.Poker,
            [licences.CAON]: gameTypes.TablesGames,
            [licences.EE]: gameTypes.Poker,
            [licences.MX]: gameTypes.Poker,
        },
        [gameTypes.Dice]: {
            [licences.MT]: gameTypes.TablesGames,
            [licences.SE]: gameTypes.Dice,
            [licences.CAON]: gameTypes.TablesGames,
            [licences.EE]: gameTypes.Dice,
            [licences.MX]: gameTypes.Dice,
        },
        [gameTypes.Gameshow]: {
            [licences.MT]: gameTypes.TablesGames,
            [licences.SE]: 'M/C',
            [licences.CAON]: gameTypes.TablesGames,
            [licences.EE]: gameTypes.Gameshow,
            [licences.MX]: gameTypes.Gameshow,
        },
        [gameTypes.Mines]: {
            [licences.MT]: 'M/C',
            [licences.SE]: 'M/C',
            [licences.CAON]: 'M/C',
            [licences.EE]: gameTypes.Mines,
            [licences.MX]: gameTypes.Mines,
        },
        [gameTypes.Crash]: {
            [licences.MT]: 'M/C',
            [licences.SE]: 'M/C',
            [licences.CAON]: 'M/C',
            [licences.EE]: gameTypes.Crash,
            [licences.MX]: gameTypes.Crash,
        },
        [gameTypes.VideoPoker]: {
            [licences.MT]: gameTypes.Other,
            [licences.SE]: gameTypes.VideoPoker,
            [licences.CAON]: gameTypes.VideoPoker,
            [licences.EE]: gameTypes.VideoPoker,
            [licences.MX]: gameTypes.VideoPoker,
        },
        [gameTypes.ScratchCard]: {
            [licences.MT]: gameTypes.Other,
            [licences.SE]: 'N/A',
            [licences.CAON]: gameTypes.ScratchCardStyleGames,
            [licences.EE]: gameTypes.ScratchCard,
            [licences.MX]: gameTypes.ScratchCard,
        },
        [gameTypes.Bingo]: {
            [licences.MT]: gameTypes.Other,
            [licences.SE]: 'N/A',
            [licences.CAON]: 'M/C',
            [licences.EE]: gameTypes.Bingo,
            [licences.MX]: gameTypes.Bingo,
        },
    },
    live: {
        [gameTypes.Blackjack]: {
            [licences.MT]: gameTypes.LiveCasino,
            [licences.SE]: gameTypes.Blackjack,
            [licences.CAON]: gameTypes.LiveDealerTables,
            [licences.EE]: gameTypes.Blackjack,
            [licences.MX]: gameTypes.Blackjack,
        },
        [gameTypes.Roulette]: {
            [licences.MT]: gameTypes.LiveCasino,
            [licences.SE]: gameTypes.Roulette,
            [licences.CAON]: gameTypes.LiveDealerTables,
            [licences.EE]: gameTypes.Roulette,
            [licences.MX]: gameTypes.Roulette,
        },
        [gameTypes.Baccarat]: {
            [licences.MT]: gameTypes.LiveCasino,
            [licences.SE]: gameTypes.Baccarat,
            [licences.CAON]: gameTypes.LiveDealerTables,
            [licences.EE]: gameTypes.Baccarat,
            [licences.MX]: gameTypes.Baccarat,
        },
        [gameTypes.Puntobanco]: {
            [licences.MT]: gameTypes.LiveCasino,
            [licences.SE]: gameTypes.Puntobanco,
            [licences.CAON]: gameTypes.LiveDealerTables,
            [licences.EE]: gameTypes.Puntobanco,
            [licences.MX]: gameTypes.Puntobanco,
        },
        [gameTypes.Poker]: {
            [licences.MT]: gameTypes.LiveCasino,
            [licences.SE]: gameTypes.Poker,
            [licences.CAON]: gameTypes.LiveDealerTables,
            [licences.EE]: gameTypes.Poker,
            [licences.MX]: gameTypes.Poker,
        },
        [gameTypes.Dice]: {
            [licences.MT]: gameTypes.LiveCasino,
            [licences.SE]: gameTypes.Dice,
            [licences.CAON]: gameTypes.LiveDealerTables,
            [licences.EE]: gameTypes.Dice,
            [licences.MX]: gameTypes.Dice,
        },
        [gameTypes.Gameshow]: {
            [licences.MT]: gameTypes.LiveCasino,
            [licences.SE]: 'M/C',
            [licences.CAON]: gameTypes.LiveDealerTables,
            [licences.EE]: gameTypes.Gameshow,
            [licences.MX]: gameTypes.Gameshow,
        },
        [gameTypes.Bingo]: {
            [licences.MT]: gameTypes.LiveCasino,
            [licences.SE]: 'N/A',
            [licences.CAON]: gameTypes.LiveDealerTables,
            [licences.EE]: gameTypes.Bingo,
            [licences.MX]: gameTypes.Bingo,
        },
    },
});

const casinoTranslationsKeys = [
    'casino.race.race-leaderboard-name',
    'casino.race.race-leaderboard',
    'casino.race.race-leaderboard-new',
];

export type BlockType = 'countries' | 'provinces';

export async function loadLabelTranslations() {
    try {
        const translations = await getTranslations(CasinoTranslationGroupKeys.Labels);
        const translationsById = keyBy(translations, 'id');
        store.casino.gameLabelTranslations.set(translationsById);
    } catch (error) {
        logger.error('CasinoService', 'loadLabelTranslations', error);
        notification.error({
            message: 'Error while fetching game label translations',
        });
    }
}

export async function initCasino(): Promise<void> {
    try {
        Promise.all([
            loadLicences(),
            loadProviders(),
            loadSubproviders(),
            loadStudios(),
            loadRtpVersions(),
            loadTypes(),
            loadThemes(),
            loadLabels(),
            loadCountries(),
            loadProvinces(),
            loadCurrencies(),
            loadFeatures(),
            loadOperatingLanguagesToStore(),
            loadCasinoTranslations(),
        ]).then(() => {
            store.casino.isLoaded.set(true);
        });
    } catch (error) {
        logger.error('CasinoService', 'initCasino', error);
        notification.error({
            message: 'Error while trying to get casino info',
        });
    }
}

export function downloadGameTableSample() {
    const headerRow =
        'id;name;isPublic;jackpotName;wageringContribution;isEnabled;createdAt;updatedAt;externalCategoryId;gameType;' +
        'releaseDate;lines;reels;xWin;rtp;volatility;hitRate;backgroundImageName;subProviderId;providerId;minRtpMga;' +
        'minRtpCur;minRtpEmta;minRtpSga;baseRtp;minRtpOnt;operatingLanguageId;imageId;studioId;minRtpMex;boUserId;' +
        'rtpVersionId;groupId;labels;categories;themes;disabledWithBonusCountries;gameVariants;betSizes;gameInfo;' +
        'properties;type;externalId;gameTypeToLicenseMappings;gameLogos;certifications;blockedInCountryIds;blockedInProvinceIds';
    const dataRow =
        '1;testGame;TRUE;jackpot1;100;TRUE;2000-01-01;2000-01-01;testOut;casino;2000-01-01;100;5;3825;96.48;Medium;17.38;' +
        'test_image_4194.png;1;1;87.46;84.47;84.47;84.47;96.48;84.47;1;19;5;90.24;437;2;14;1,2,39;1,2,13;2,7,11;3,16;' +
        '[{"id":1390,"serverGameId":"test_game_1","mode":"both","isPublished":true,"externalGameName":"test_game_ext_1",' +
        '"tableId":"1204","mgClientId":"54","mgModuleId":"21","createdAt":"2000-01-01","updatedAt":"2000-01-01",' +
        '"jurisdictions":[1,2,3]}];[{"id":1,"licenceId":4,"currencyId":4,"minBet":2,"maxBet":2000}];{"en":{"description":' +
        '"This is a test.","linesComment":null,"rtpComment":null,"maxWinMultiplierComment":null},"et":{"description":"See on test."}};' +
        '14,6;1;TESTGAME1;[{"licence":1,"gameType":1},{"licence":2,"gameType":1},{"licence":3,"gameType":1}];[{"id":1523,' +
        '"type":"PROVIDER","imageName":"test_game_120985.png","licenceIds":[1,2]}];[{"id":7,"licenceId":2,"name":"J592_G001_TESTGAME_REV.1",' +
        '"issueDate":"2000-01-01","expiryDate":"2024-02-17T00:00:00+00:00","link":"https://drive.google.com/file/d/d21n98s","isEnabled":false}];3,35;11';
    return { headerRow: headerRow, dataRow: dataRow };
}

export async function loadOperatingLanguagesToStore(): Promise<void> {
    const languages = await loadOperatingLanguages();
    languages && store.casino.operatingLanguages.set(languages);
}

export async function loadThemeTranslations() {
    try {
        const translations = await getTranslations(CasinoTranslationGroupKeys.Themes);
        const translationsById = keyBy(translations, 'id');
        store.casino.gameThemeTranslations.set(translationsById);
    } catch (error) {
        logger.error('CasinoService', 'loadThemeTranslations', error);
        notification.error({
            message: 'Error while fetching game theme translations',
        });
    }
}

export async function loadFeatureTranslations() {
    try {
        const translations = await getTranslations(CasinoTranslationGroupKeys.Features);
        const translationsById = keyBy(translations, 'id');
        store.casino.gameFeatureTranslations.set(translationsById);
    } catch (error) {
        logger.error('CasinoService', 'loadFeatureTranslations', error);
        notification.error({
            message: 'Error while fetching game feature translations',
        });
    }
}

function getTranslationKey(groupKey: string, itemId: number): string {
    return `${groupKey}.${itemId}`;
}

export function getLabelTranslationKey(id: number): string {
    return getTranslationKey(CasinoTranslationGroupKeys.Labels, id);
}

export function getThemeTranslationKey(id: number): string {
    return getTranslationKey(CasinoTranslationGroupKeys.Themes, id);
}

export function getFeatureTranslationKey(id: number): string {
    return getTranslationKey(CasinoTranslationGroupKeys.Features, id);
}

export function generateLobbyTabList(markets: string[]): Tab[] {
    if (markets.length) {
        return markets.map((market: string) => ({
            key: market,
            label: market.length === 2 ? market.toUpperCase() : market.slice(0, 1).toUpperCase() + market.slice(1),
        }));
    }
    return [];
}

export function generateLobbyTranslationsTableData(
    text: CmsTranslation['text'],
    country: string,
): LanguageLobbyTabular[] {
    const languages = getLanguages();

    return languages.map(({ value, title, country_value }) => ({
        language: value,
        text: text[value] || '-',
        countryCode: country_value.toUpperCase() as Country,
        title,
    })) as LanguageLobbyTabular[];
}

export async function loadCasinoTranslations() {
    const fallbackLanguage = getFallbackLanguage();
    const translations = await getTranslationsByKeys(casinoTranslationsKeys);
    const mappedTranslations: Map<string, string> = new Map();
    if (translations) {
        translations.forEach(translation => {
            mappedTranslations.set(translation.key, translation.text[fallbackLanguage]);
        });
    }
    store.casino.casinoTranslations.set(mappedTranslations);
}

export function formatClientCurrencies(currencies: Currency[]) {
    const { AVAILABLE_LICENSES } = getStoreValue(store.environment);
    const isSimGamingClient = AVAILABLE_LICENSES.includes(Licence.SIMULATED_GAMING);
    let filteredCurrencies = currencies;
    if (isSimGamingClient) {
        filteredCurrencies = currencies.filter(currency => currency?.currency === 'VCD');
    }
    store.casino.currencies.set(filteredCurrencies);
    return filteredCurrencies;
}

export function isCasinoRmgClient() {
    const { CLIENT_NAME } = getStoreValue(store.environment);
    return CLIENT_NAME === ClientName.SOARING_EAGLE;
}
