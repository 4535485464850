import React from 'react';
import {
    BookOutlined,
    ContainerOutlined,
    DatabaseOutlined,
    GiftOutlined,
    FundOutlined,
    HomeOutlined,
    IdcardOutlined,
    MenuOutlined,
    RobotOutlined,
    SecurityScanOutlined,
    SettingOutlined,
    ShopOutlined,
    UserOutlined,
    WalletOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { getRoute, useRouter } from 'services/router';
import Wrapper from './styles';
import { HeaderUserSettings } from './user-settings/HeaderUserSettings';
import { isMobile } from 'services/browser';
import { ROLES, useKeycloak } from 'services/keycloak';
import { HeaderLogo } from './logo/HeaderLogo';
import { ClientName } from '../../types/client';
import { color } from '../../style/variables';
import {
    getAdministrationViews,
    getCasinoViews,
    getCmsViews,
    getDataWarehouseViews,
    getFeatureViews,
    getMarketingViews,
    getPaymentsViews,
    getPokerViews,
    getRacebookViews,
    getRetailViews,
    getSecurityViews,
    getSportsbookSettingsViews,
    getSportsbookViews,
    getUsersViews,
    MenuItem,
    ViewWithFeature,
    getDevToolsViews,
} from '../../services/header';
import { ROUTES } from '../../routes';
import { getStoreValue, store } from '../../stores/store';
import { isFeatureAvailable } from 'services/features';
import { showLeaderboards } from 'services/casino/casino-race';

export default function Header() {
    const keycloak = useKeycloak();
    const { ENVIRONMENT, CLIENT_NAME, QUALIFIED_NAME } = getStoreValue(store.environment);
    const { location } = useRouter();
    const pathRegex = /(\/[A-Z-]*)/i;
    const currentLink = location => location.pathname.match(pathRegex)[0];

    const headerColorByClient = {
        [ClientName.IVC]: color.blue300,
        [ClientName.COOLBET]: color.green300,
        [ClientName.WYNNBET]: color.geekblue3,
        [ClientName.WYNNBET_MA]: color.geekblue3,
        [ClientName.WYNNBET_NV]: color.geekblue3,
        [ClientName.WYNNBET_WV]: color.geekblue3,
    };

    const menuItems: MenuItem[] = [
        {
            views: getSportsbookViews(),
            icon: <HomeOutlined />,
            displayName: 'sportsbook',
            feature: 'sportsbook',
        },
        {
            views: getSportsbookSettingsViews(),
            icon: <SettingOutlined />,
            displayName: 'sportsbook settings',
            feature: 'sportsbook',
        },
        {
            views: getRacebookViews(),
            icon: <BookOutlined />,
            displayName: 'racebook',
        },
        {
            views: getUsersViews(),
            icon: <UserOutlined />,
            displayName: 'Users',
        },
        {
            views: getSecurityViews(),
            icon: <SecurityScanOutlined />,
            displayName: 'Security',
        },
        {
            views: getCmsViews(),
            icon: <ContainerOutlined />,
            displayName: 'cms',
        },
        {
            views: getMarketingViews(),
            icon: <GiftOutlined />,
            displayName: 'marketing',
        },
        {
            views: getPaymentsViews(),
            icon: <WalletOutlined />,
            displayName: 'payments',
        },
        {
            views: getCasinoViews(showLeaderboards()),
            icon: <ContainerOutlined />,
            displayName: 'casino',
            feature: 'casino',
        },
        {
            views: getPokerViews(),
            icon: <ContainerOutlined />,
            displayName: 'poker',
            feature: 'poker',
        },
        {
            views: getDataWarehouseViews(),
            icon: <DatabaseOutlined />,
            displayName: 'dwh',
        },
        {
            views: getRetailViews(),
            icon: <ShopOutlined />,
            displayName: 'retail',
        },
        {
            views: getFeatureViews(),
            icon: <DatabaseOutlined />,
            displayName: 'features',
        },
        {
            views: getAdministrationViews(),
            icon: <IdcardOutlined />,
            displayName: 'administration',
        },
        {
            views: getDevToolsViews(),
            icon: <SettingOutlined />,
            displayName: 'dev tools',
        },
    ];

    if (ENVIRONMENT !== 'dev') {
        const maintenanceViews: ViewWithFeature[] = [
            {
                title: 'Sportsbook',
                to: getRoute(ROUTES.maintain),
                feature: 'maintenance',
                permissions: [ROLES.SBGATE.MAINTENANCE],
            },
        ];
        menuItems.push({
            views: maintenanceViews as ViewWithFeature[],
            icon: <GiftOutlined />,
            displayName: 'Maintenance',
        });
    }

    if (ENVIRONMENT !== 'prod') {
        const testViews: ViewWithFeature[] = [
            {
                title: 'Bot',
                to: getRoute(ROUTES.bot),
                permissions: [ROLES.SPORTS.SPORTS],
            },
            {
                title: 'My Dev Journey',
                to: getRoute(ROUTES.devJourney),
                permissions: [ROLES.ASSET_MANAGEMENT.IT_DEPARTMENT_VIEW],
            },
        ];
        menuItems.push(
            {
                views: [
                    {
                        title: 'Chat',
                        to: getRoute(ROUTES.chat),
                        feature: 'eiai',
                    },
                ],
                icon: <RobotOutlined />,
                displayName: 'AI chat',
            },
            {
                views: testViews,
                icon: <RobotOutlined />,
                displayName: 'Dev',
            },
        );
    }

    menuItems.push({
        views: [
            {
                title: 'Reports',
                to: getRoute(ROUTES.reporting),
                permissions: [ROLES.REPORTING.VIEW],
            },
        ],
        icon: <FundOutlined />,
        displayName: 'Reporting',
    });

    const menu = menuItems.map(item => {
        const views = getEnabledViews(item);
        if (!views.length) {
            return null;
        }
        return {
            key: `menu-${item.displayName}`,
            icon: item.icon,
            label: item.displayName.toUpperCase(),
            popupOffset: [0, 0],
            children: views.map(view => {
                const title = view.title.toUpperCase();
                return {
                    key: view.to,
                    label: view.children ? title : <Link to={view.to}>{view.title.toUpperCase()}</Link>,
                    children: view.children?.map(childView => {
                        return {
                            key: childView.to,
                            label: <Link to={childView.to}>{childView.title.toUpperCase()}</Link>,
                        };
                    }),
                };
            }),
        };
    });

    function getBackgroundColor() {
        if (ENVIRONMENT !== 'prod') {
            return color.gold400;
        }

        if (QUALIFIED_NAME === 'coolbet-pe') {
            return color.red300;
        }

        return headerColorByClient[CLIENT_NAME];
    }

    function getEnabledViews(item: MenuItem) {
        if (item.feature && !isFeatureAvailable(item.feature)) {
            return [];
        }

        return item.views
            .filter(view => {
                if (view.children) {
                    view.children = view.children.filter(child => !child.feature || isFeatureAvailable(child.feature));
                    if (!view.children.length) {
                        return false;
                    }
                }
                return !view.feature || isFeatureAvailable(view.feature);
            })
            .filter(view => keycloak.hasAccessForPermissions(view.permissions));
    }

    return (
        <Wrapper backgroundColor={getBackgroundColor()}>
            <HeaderLogo />
            <Menu
                mode="horizontal"
                theme="dark"
                className="header-menu"
                selectedKeys={[currentLink({ ...location })]}
                overflowedIndicator={<MenuOutlined />}
                items={isMobile() ? [{ key: 'menu', label: 'MENU', children: menu }] : menu}
            />
            <HeaderUserSettings />
        </Wrapper>
    );
}
