import { message } from 'antd';
import { httpGetBinaryFile } from 'services/api';
import { logger } from 'services/logger';

export enum DOWNLOAD_EXTENSIONS {
    CSV = 'csv',
    ODB = 'odb',
    XML = 'xml',
    XLS = 'xls',
    XLSX = 'xlsx',
    PDF = 'pdf',
    JPEG = 'jpeg',
}

export function downloadFile(
    filename: string,
    data: string | Blob,
    fileExt: DOWNLOAD_EXTENSIONS,
    config?: { disableCsvUtf8?: boolean },
) {
    let fileType;
    let blobParts;
    switch (fileExt) {
        case DOWNLOAD_EXTENSIONS.CSV:
            fileType = config?.disableCsvUtf8 ? 'text/csv' : 'text/csv;charset=utf-8';
            blobParts = config?.disableCsvUtf8 ? [data] : [new Uint8Array([0xef, 0xbb, 0xbf]), data];
            break;
        case DOWNLOAD_EXTENSIONS.XML:
            fileType = 'text/xml';
            blobParts = [data];
            break;
        case DOWNLOAD_EXTENSIONS.XLS:
        case DOWNLOAD_EXTENSIONS.XLSX:
            fileType = 'application/octet-stream';
            blobParts = [toOctetBuffer(data)];
            break;
        case DOWNLOAD_EXTENSIONS.ODB:
            fileType = 'application/vnd.oasis.opendocument.database';
            blobParts = [data];
            break;
        case DOWNLOAD_EXTENSIONS.PDF:
            fileType = 'application/pdf';
            blobParts = [data];
            break;
        case DOWNLOAD_EXTENSIONS.JPEG:
            fileType = 'image/jpeg';
            blobParts = [data];
            break;
    }
    if (typeof data !== 'string' && !(data instanceof Blob)) {
        return;
    }
    const blob = new Blob(blobParts, {
        type: fileType,
    });
    const href = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = href;
    a.target = '_blank';
    a.download = `${filename}.${fileExt}`;
    a.click();
    return href;
}

function toOctetBuffer(data) {
    const buf = new ArrayBuffer(data.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < data.length; i++) {
        view[i] = data.charCodeAt(i) & 0xff;
    }
    return buf;
}

export async function handleDownloadImage(imageUrl: string, imageTitle: string) {
    try {
        const imageBlob = await httpGetBinaryFile(imageUrl);
        downloadFile(imageTitle, imageBlob, DOWNLOAD_EXTENSIONS.JPEG);
    } catch (error) {
        logger.error('FileService', 'handleDownloadImage', error);
        message.error('Could not download media');
    }
}
