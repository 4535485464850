import { TeamInfo } from '@staycool/sports-types/teams';
import { ObjectValues } from './util';

export interface Client {
    id: number;
    name: string;
    is_filter_enabled: boolean;
    backoffice_url: string;
    url: string;
}

export interface ClientMarketType {
    id: number;
    active: boolean;
    name: string;
    market_type_group_id: number;
    market_type_group_name: string;
    client_enabled: boolean;
    sport_id: number;
    sport_name: string;
}

export interface ClientCategory {
    id: number;
    name: string;
    parent_id: number;
    parent_name: string;
    is_enabled: boolean;
    sport_id: number;
    sport_name: string;
    active: boolean;
    childCategories?: ClientCategory[];
    is_manual_match_launch: boolean;
    whitelisted_teams: TeamInfo[];
    whitelisted_team_ids: number[];
    market_types_template_id: number;
    inplayProviderPriority: string[];
    prematchProviderPriority: string[];
}

export interface ClientCategoryConfiguration {
    is_manual_match_launch?: boolean;
    whitelisted_teams?: WhitelistedTeam[];
    market_types_template_id: number;
}

export type CategoryConfiguration = Record<
    number,
    { is_manual_match_launch: boolean; market_types_template_id: number; is_enabled: boolean }
>;

export interface WhitelistedTeam extends TeamInfo {
    selected?: boolean;
}

export const VENUE = {
    HOME: 'HOME',
    AWAY: 'AWAY',
} as const;

export type VenueType = ObjectValues<typeof VENUE>;

export interface ClientTeam extends TeamInfo {
    client_enabled: boolean;
    venue?: VenueType;
    overriden_match_ids?: number[];
}

export interface ClientTeamsSearchParams {
    teamIds?: number[];
    teamName?: string;
    sport?: number;
    region?: number;
    onlyDisabled?: boolean;
    limit?: number;
}

export interface ClientCategoryTree {
    [sports: string]: ClientCategory[];
}

export interface CategoryConfigPayload {
    category_id: number;
    is_enabled: boolean;
    market_types_template_id: number;
    is_manual_match_launch?: boolean;
    whitelisted_team_ids?: number[];
}

export interface DisabledTeamInputParams {
    team_id: number;
    venue?: VenueType | null;
    overriddenMatchIds?: number[];
}

export enum CategoryDepth {
    SPORT = 2,
    REGION = 3,
    LEAGUE = 4,
}

export enum ClientName {
    COOLBET = 'coolbet',
    COOLBET_PERU = 'coolbet-pe',
    DEMO = 'demo',
    FONTAINEBLEAU_NV = 'fontainebleau-nv',
    IVC = 'ivc',
    OAKLAWN = 'oaklawn',
    SOARING_EAGLE = 'soaring-eagle',
    SPORTSBOOKCORE = 'sportsbook-core',
    STATION = 'station',
    WYNNBET = 'wynnbet',
    WYNNBET_CORE = 'wynnbet-core',
    WYNNBET_MA = 'wynnbet-ma',
    WYNNBET_MA_WEB = 'wynnbet-ma-web',
    WYNNBET_NV = 'wynnbet-nv',
    WYNNBET_NY = 'wynnbet-ny',
    WYNNBET_WV = 'wynnbet-wv',
}

export enum FaviconTypes {
    COOLBET = 'favicon_coolbet',
    GAN = 'favicon_gan',
    IVC = 'favicon_ivc',
}

export interface ClientMatchMapping {
    core_id: number;
    id: number;
    core_feed_enabled: boolean;
}

export type ClientEntity = 'language';

export type EntityFilteringEnabledByClientId = Record<number, Record<ClientEntity, boolean>>;
