export enum CasinoRaceType {
    HIGHEST_WIN_MULTIPLIER = 'HIGHEST_WIN_MULTIPLIER',
    SUM_OF_GROSS_WIN = 'SUM_OF_GROSS_WIN',
    NET_WIN = 'NET_WIN',
    SUM_OF_STAKES = 'SUM_OF_STAKES',
    SUM_OF_WINS = 'SUM_OF_WINS',
    SUM_OF_NUMBER_OF_BETS = 'SUM_OF_NUMBER_OF_BETS',
}

export enum CasinoRaceStatus {
    UPCOMING = 'UPCOMING',
    ACTIVE = 'ACTIVE',
    DRAFT = 'DRAFT',
    INACTIVE = 'CANCELLED',
    PROCESSED = 'PROCESSED',
    FINISHED = 'FINISHED',
}
export enum AWARD_TYPE {
    CUSTOM = 'custom',
    FREE_SPINS = 'free_spins',
    FREE_MONEY = 'free_money',
}
